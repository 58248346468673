.Photos {
    text-align: center;
    padding: 30px;


    h2 {
        text-align:left;
        color: navy;
        font-size: 25px;
    }

    img {
        max-width: 100%;
        max-height: 100vh;
        height: auto;
    }
}