.Intro {
background: white;
padding : 20px;
color: white;

    .title {
        color: navy;
        font-size: 25px;
        margin: 10px;
        font-weight: bold;
    }

    .intro {
        margin: 20px;
    }
}
