.AboutMe {

    padding: 30px;
    h2 {
        color: navy;

    font-size: 25px;
    }

    .Bio {}

    .Interests {}

    .Experiences {}

    .Projects {}

    .Organizations {}

    .Achievements {}

}