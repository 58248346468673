.Message {
    line-height: 1.5em;
  }
  
  .Message.log {
    color: #bbb;
  }
  
  .Message .author {
    font-weight: bold;
    color: red;
    margin: 0 5px 0 0;
  }
  
  .Message.me .author {
    color: #1b8cce;
  }