.Header {
background: navy;
padding : 20px;
color: white;
text-align: center;

    .hero-img {
        width: 100%;
        border: 20px solid white;
    }
    
    .title {
        font-size: 60px;
        margin: 10px;
    }

    .major-title {
        font-weight: 500;
        font-size: 20px;
        margin: 10px;
    }

    .contact-list {
        padding: 0;
        list-style: none;

        .contact-element {
            display: inline-block;
            margin: 0px 20px;

            .contact-link {
                font-size:35px;
                color:white;
                width: 30px;
            }
        }
    }
}
