* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  color: #222;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content {
  height:100%;
  max-width: 800px;
  margin: auto;
}

h1 {
  font-size: 30px;
  font-weight: bold;
}

h2 {
  font-size: 25px;
  font-weight: bold;
}

h3 {
  font-size: 17px;
  font-weight: regular;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
