.ChatBot {
  background: #fff;
  border: 1px solid #000;
  border-radius: 3px;
  height: 400px;
  width: 600px;
  margin: 1em;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;  
}

/* .ChatBot, .open-button {
  position: fixed;
  bottom: 0;
  left:0;
} */

.ChatBot .MessageForm {
  background: #eee;
  border-top: 1px solid #ccc;
  border-radius: 0 0 3px 3px;
  box-sizing: border-box;
  flex: 0 0 30px;
}

.ChatBot .MessageList {
  box-sizing: border-box;
  flex: 1;
}

.ChatBot-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ChatBot-link {
  color: #61dafb;
}